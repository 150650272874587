<template>
    <div>
        <hr />
        <p>
            Tarif<br />
            {{ price }}€*<br />
            <span v-if="group">
                Possibilité d’atelier collectif sur demande
            </span>
        </p>
        <hr />
        <em>
            * des frais peuvent s'ajouter en fonction de la localisation de votre domicile.
        </em>
                
    </div>
</template>

<script>
export default {
    name: 'Price',
    props: {
        price: {
            type: Number,
            required: true,
        },
        group: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
p {
    color: #fff;
    background-color: var(--secondary);

    font-weight: bold;

    text-align: center;
}

em {
    font-size: 12px;
}
</style>

<template>
    <b-container>
        <b-row class="text-center mb-5">
            <b-col cols="12">
                <h1>Atelier bain enveloppé</h1>
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    On entend souvent les bébés pleurer au moment du bain, 
                    alors que celui-ci devrait être un moment de détente. 
                    Afin que le bain soit un instant de douceur et de plaisir 
                    partagé, je vous propose une alternative au bain 
                    « classique ». Lors de ce bain, vous apprendrez à baigner 
                    votre bébé à l’aide d’un lange pour lui permettre de vivre
                    un moment apaisant et de se sentir en confiance dans un 
                    milieu qui lui semble familier. De quoi lui rappeler les 
                    sensations dans le ventre de sa maman. Ce bain convient 
                    à tous les nouveaux nés.<br />
                    <br />
                    Cet atelier comprend un échange autour de votre histoire 
                    et celle de votre bébé, le bain et un accompagnement 
                    personnalisé autour des questions relatives à l’arrivée 
                    de votre enfant.<br />
                    <br />
                    Pour prendre rendez-vous, vous pouvez me contacter pendant 
                    la grossesse ou après la naissance de votre bébé. <br />
                    <price :price="90" />
                </p>
                <p class="d-lg-none"><br /></p>
            </b-col>
            <b-col xs="12" lg="5" style="margin-top: -45px;">
                <non-clickable-img url="wrapped_bath.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'WrappedBath',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
